/**
* Generated theme by Material Theme Generator
* https://materialtheme.arcsine.dev
* Fork at: https://materialtheme.arcsine.dev/?c=YHBhbGV0dGU$YHByaW1hcnk$YF48IzkwNDI4MSIsIj9lcjwjZGVjNmQ5IiwiO2VyPCNiNDgyYWF$LCIlPmBePCNiMTU0OWYiLCI~ZXI8I2U4Y2NlMiIsIjtlcjwjOTgzYTgzfiwid2Fybj5gXjwjZTQyODU1IiwiP2VyPCNmN2JmY2MiLCI7ZXI8I2Q5MTgzYn4sIj9UZXh0PCMwNzE0MzciLCI~PTwjZmFmYWZhIiwiO1RleHQ8I2ZmZmZmZiIsIjs9PCMxYzFkMjJ$LCJmb250cz5bYEA8KC00fixgQDwoLTN$LGBAPCgtMn4sYEA8KC0xfixgQDxoZWFkbGluZX4sYEA8dGl0bGV$LGBAPHN1YiktMn4sYEA8c3ViKS0xfixgQDxib2R5LTJ$LGBAPGJvZHktMX4sYEA8YnV0dG9ufixgQDxjYXB0aW9ufixgQDxpbnB1dCIsInNpemU$bnVsbH1dLCJpY29uczxPdXRsaW5lZCIsIj9uZXNzPnRydWUsInZlcnNpb24$MTN9
*/

@use '@angular/material' as mat;
@use './assets/theme/m3-theme';

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.

// Fonts
@import 'https://fonts.googleapis.com/icon?family=Material+Icons+Outlined';
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500&display=swap');

// Foreground Elements

// Light Theme Text

@include mat.elevation-classes();
@include mat.app-background();

html {
  // Apply the light theme by default
  @include mat.elevation-classes();
  @include mat.app-background();
  @include mat.button-theme(m3-theme.$light-theme);
  @include mat.all-component-themes(m3-theme.$light-theme);

  @include mat.system-level-colors(m3-theme.$light-theme);
  @include mat.system-level-typography(m3-theme.$light-theme);
  @include mat.color-variants-backwards-compatibility(m3-theme.$light-theme);

  --sys-surface-container: #fff;
}

// Specific component overrides, pieces that are not in line with the general theming

// Handle buttons appropriately, with respect to line-height
.mat-raised-button,
.mat-stroked-button,
.mat-flat-button {
  /* padding: 0 1.15em;
  margin: 0 0.65em;
  min-width: 3em;
  line-height: 36.4px;*/
}

.mat-standard-chip {
  padding: 0.5em 0.85em;
  min-height: 2.5em;
}

.material-icons {
  font-size: 24px;
  font-family: 'Material Icons Outlined', 'Material Icons';
  .mat-badge-content {
    font-family: 'Roboto Flex';
  }
}

.mat-mdc-outlined-button .mat-mdc-button-ripple,
.mat-mdc-outlined-button .mdc-button__ripple {
  border-width: 0px !important;
}

.mat-mdc-outlined-button:not(:disabled) {
  border-color: mat.get-theme-color(m3-theme.$light-theme, neutral, 70) !important;
}

/* You can add global styles to this file, and also import other style files */
@import './assets/sass/style.scss';
// Replace above style with this css file to enable RTL styles
// @import "./assets/css/style.rtl";
@import './assets/sass/plugins.scss';
// @import "./assets/css/style.rtl.css";
@import './assets/sass/style.angular.scss';

@import 'material-icons/iconfont/material-icons.css';

// Keenicons - High quality and pixel perfect font icons available in 3 styles, duotone, outline and solid for Metronic elements
@import './assets/plugins/keenicons/duotone/style.css';
@import './assets/plugins/keenicons/outline/style.css';
@import './assets/plugins/keenicons/solid/style.css';

@import 'mat-icon-button-sizes/style.css';

@media only screen and (max-width: 760px) {
  .cdk-overlay-pane {
    max-width: 95vw !important;
  }
}

input {
  font-size: 16px !important;
}

.mdc-text-field__input:not(.mat-mdc-form-field-textarea-control) {
  height: 28px !important;
}

*:disabled {
  cursor: not-allowed !important;
  pointer-events: auto !important;
}

.mat-mdc-snack-bar-container {
  &.error-snackbar {
    --mdc-snackbar-container-color: #f44336 !important;
    --mat-mdc-snack-bar-button-color: #fff !important;
    --mdc-snackbar-supporting-text-color: #fff !important;

    .mat-mdc-snack-bar-label {
      font-weight: 600;
    }
  }
}

.dialog-title {
  display: flex !important;
  justify-content: space-between;
  align-items: center;
  padding-right: 0px !important;

  h2 {
    margin: 0 -24px 0 0;
    flex: 1;
    text-align: center;
  }
}

span {
  white-space: pre-wrap;
}

.mat-mdc-menu-content {
  padding: 0 !important;
}

.big-menu .mat-mdc-menu-item {
  padding: 16px 24px !important;
}

.mdc-tooltip {
  white-space: pre-line !important;
}

.mdc-icon-button .mat-icon {
  //line-height: 21px;
}

.mat-mdc-checkbox .mat-internal-form-field,
.mat-mdc-radio-button .mat-internal-form-field {
  font-size: 16px !important;
}

.mat-mdc-mini-fab {
  box-shadow: none !important;
}

.mat-mdc-dialog-content {
  max-height: 75vh !important;
}

.mat-mdc-form-field-required-marker {
  font-weight: 700;
  color: #f8285a; //  352b30
}

.full-width-dialog.mat-mdc-dialog-panel {
  max-width: 100vw !important;
}

.mdc-text-field--outlined {
  height: 56px !important;
}

app-textarea-question .mat-mdc-text-field-wrapper {
  height: auto !important;
}

.badge-grey {
  border: 1px solid #abafc6;
  color: #abafc6;
}

.ngx-charts-tooltip-content {
  .tooltip-content {
    display: flex;
    position: relative;

    & > span {
      display: flex;
      flex-direction: column;
      gap: 4px;

      .tooltip-label {
        font-size: 14px;
        font-weight: 800;
      }

      .tooltip-val {
        font-size: 14px;
      }
    }
  }
}

.pie-label {
  font-weight: 500;
  font-size: 12px !important;
}

.text-justify {
  text-align: justify !important;
}

.mat-mdc-select-min-line {
  white-space: nowrap !important;
}

.flex-1 {
  flex: 1;
}